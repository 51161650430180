import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import banking from '../../images/slicing/industries/banking.jpeg'
import healthcare from '../../images/slicing/industries/healthcare.jpeg'
import industrial from '../../images/slicing/industries/industrial.jpeg'
import digitalcommerce from '../../images/slicing/industries/digitalcommerce.jpeg'
class IndustryComponent extends Component {
    render() {
        return (
            <Container fluid className="py-5">
                <Container className="py-5">
                    <Row className="pt-3">
                        <Col>
                            <h3 className="text-standard font-weight-bold">Banking, Financial Services & Insurance</h3>
                            <p className="industries-paragrapgh">
                                In the new Digital World as traditional institutions and fintech’s strive for growth
                                and enhanced customer engagement to improve margins by reducing complexity and
                                optimizing operations. We help our clients to achieve this through a digital highway
                                of disruptive technologies and partnerships with leading technology platform
                                providers – enabling delivery of personalized financial services that are valued by
                                their customers.
                            </p>
                            <p className="industries-paragrapgh">
                                Talk to us if you are looking to extend your existing systems with a cloud-based
                                platform of micro-services, pre-built integrations and accelerators enabling rapid
                                deployment of new digital banking, insurance or financial services capabilities.
                            </p>
                        </Col>
                        <Col className="d-none d-md-flex d-xl-flex">
                            <img width="100%" height="350px" src={banking} alt="Not Found" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="standard-background">
                    <Container className="py-5">
                        <Row className="pt-3 ">
                            <Col className="d-none d-md-flex d-xl-flex">
                                <img width="100%" height="400px" src={healthcare} alt="Not Found" />
                            </Col>
                            <Col>
                                <h3 className="text-dark font-weight-bold">Healthcare & Life Sciences</h3>
                                <p className="industries-paragrapgh">
                                    If anything, current world scenario has taught all of us is how important is
                                    personal health and access to health care data is. With this need of having access
                                    to health care data at all times on all devices, media requires technology innovation
                                    with process transformation to help reduce cost and enhance customer experience.
                                    We drive transformational programs with industry leaders that span the areas of
                                    patient engagement, care delivery,
                                    clinical trials, operations, as well as device and diagnostic development.
                                </p>
                                <p className="industries-paragrapgh">
                                    Connect with us if you want to improve customer engagement by leveraging latest in mobile
                                    technologies or building a scalable platform on the cloud.
                                </p>
                                <p className="industries-paragrapgh">
                                    We help you to establish a Digital Front Door that enable an omni channel
                                    experience for patients, care providers and agents on a single digital
                                    platform that supports all phases of patient interaction and care.
                                    Digital Clinical Care develop and implement digital care pathways
                                    for the treatment of complex patients with chronic conditions.
                                </p>
                            </Col>

                        </Row >
                    </Container>

                </Container>
                <Container fluid>
                    <Container className="py-5">
                        <Row className="pt-3">
                            <Col>
                                <h3 className="text-standard font-weight-bold">Industrial & Hi-Tech</h3>
                                <p className="industries-paragrapgh">
                                    We deliver business value by tightly integrating legacy design and
                                    engineering, manufacturing and operational systems with industry
                                    leading processes, software and analytics capabilities.
                                </p>
                                <p className="industries-paragrapgh">
                                    The result is an integrated technology stack that ensures a seamless
                                    flow of consistent, correct and complete data from product design and
                                    engineering to manufacturing and beyond.
                                </p>
                                <p className="industries-paragrapgh">
                                    Technology products and business models don’t stay current for long
                                    due to the rapid pace of innovation, accelerated development cycles,
                                    and disruptive business models. This has increased both the importance
                                    and complexity of managing innovation, engineering, and management.
                                </p>
                            </Col>
                            <Col className="d-none d-md-flex d-xl-flex">
                                <img width="100%" height="350px" src={industrial} alt="Not Found" />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="standard-background">
                    <Container className="py-5">
                        <Row className="pt-3">
                            <Col className="d-none d-md-flex d-xl-flex">
                                <img width="100%" height="300px" src={digitalcommerce} alt="Not Found" />
                            </Col>
                            <Col>
                                <h3 className="text-dark font-weight-bold">Digital Commerce</h3>
                                <p className="industries-paragrapgh">
                                    As retailers continue to see rapid shifts in customer demands,
                                    competitors’ capabilities and available technologies,
                                    the pressure for omnichannel transformation has reached a
                                    fevered pitch. Every retailer is investing, but which capabilities
                                    are most important and how do retailers build them out?
                                </p>
                                <p className="industries-paragrapgh">
                                    GTEN’s experts help retailers to find the right path
                                    to omnichannel adoption for their brand, based on their
                                    customers, competitors and physical and digital assets.
                                    Each retailer’s path is unique. It requires finding the right
                                    mix of stores, mobile, digital marketing and CRM capabilities.
                                </p>
                            </Col>

                        </Row>
                    </Container>
                </Container>
            </Container>
        )
    }
}

export default IndustryComponent