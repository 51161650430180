export const offeringsIndustries = [
    [{
        heading: 'Digital Business Strategy',
        description: 'Innovation accelerators and design studios to deliver new-age experience-driven solutions and open new revenue stream.'
    },
    {
        heading: 'Digital Product Engineering',
        readMore:'/productengineering/',
        description: 'Software and platform engineering services to build, modernize and sustain software with break through efficiency.'
    },
    {
        heading: 'CX Innovation & Optimization',
        description: 'Leverage low-code, no-code paradigms to deliver world-class customer experiences across all digital and in-person touch points, enabling you to better serve and grow your customer base.'
    }],
    [{
        heading: 'Data-Driven Business & Intelligence',
        description: 'The power of data coupled with AI/ML to uncover actionable insights around user behaviour to provide hyper-personalized, low aperture digital experiences.'
    },
    {
        heading: 'Identity, Access & Privacy',
        description: 'Deliver privacy-aware and identity-centric customer experiences while protecting userdata, eliminating product vulnerabilities and staying ahead of compliance requirements.'
    },
    {
        heading: 'Legacy Modernization',
        readMore:'/legacymodernization/',
        description: 'Rearchitect and refactor traditional software products to create new cloud-first, cloud-native enterprise applications and platforms.'
    }]
]