import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import DomainsContainer from "../containers/DomainsContainer"

const CloudNative = (props) => (
  <Layout 
  pageInfo={{ pageName: "Industries" }} 
  styleClass="home-page" 
  location={'/industries/'}>
    <SEO title="Industries" />
    <DomainsContainer />
  </Layout>
)

export default CloudNative
