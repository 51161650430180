import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import { offeringsIndustries } from '../staticdata/industriesData'
import TestimonialComponent from '../components/services/TestimonialComponent'
import ServicesNewComponent from '../components/services/ServicesNewComponent'
import IndustryComponent from '../components/services/IndustryComponent'

class DomainsContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Industries"
                    bannerImage={banner}
                    text="We ask the right questions, give the right suggestions and carry out the right actions, to deliver the best outcomes." />
                <IndustryComponent/>
                <ServicesNewComponent
                    heading="Offerings & solutions"
                    height="250px"
                    bgColor='#ffffff'
                    page="services"
                    data={offeringsIndustries} />
                <CaseStudyComponent />
                <TestimonialComponent />
            </>
        )
    }
}

export default DomainsContainer